import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import findIndex from 'lodash-es/findIndex';
import { WorkerService } from '@services/worker/worker.service';
import find from 'lodash-es/find';

export class Breadcrumb {
  private _label: string = null;
  private _dynamicLabel: string = null;
  public get label(): string {
    return this._dynamicLabel ? this._dynamicLabel : this._label;
  }
  public get originalLabel(): string {
    return this._label;
  }
  public truncate: number = null;
  public url: string = null;
  public isDynamic: boolean = false;
  public isLoading: boolean = false;
  public constructor(initData?: Partial<Breadcrumb> | string) {
    if (typeof initData === 'string') {
      this._label = initData;
    } else {
      this._label = initData?.label;
      this.url = initData?.url;
      this.truncate = initData?.truncate;
      this.isDynamic = initData?.isDynamic ?? false;
      this.isLoading = this.isDynamic;
    }
  }

  public setDynamicLabel(token: string, tokenValue: string): void {
    if (!this.isDynamic) {
      throw new Error('Tried to set dynamic label with token of non dynamic breadcrumb');
    }
    const tokenIndex = this._label.indexOf(token);
    if (tokenIndex === -1) {
      throw new Error('Tried to replace breadcrumb token but the token does not exist');
    }
    this._dynamicLabel = this._label;
    this._dynamicLabel = this._dynamicLabel.replace(token, tokenValue);
    this.isLoading = false;
  }
}

export enum BreadcrumbRouteToken {
  ClientName = '{clientName}',
  CompanyName = '{companyName}',
  UserName = '{userName}',
  WorkerName = '{workerName}',
  WelcomeWorkerName = 'Welcome back, {workerName}!',
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbTrail: Breadcrumb[] = [];
  private breadcrumbTrail$ = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$ = this.breadcrumbTrail$.asObservable();
  private workerName: string = null;

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workerService: WorkerService
  ) {
    this.breadcrumbTrail = this.createBreadcrumbs(this.activatedRoute.root);
    this.breadcrumbTrail$.next(this.breadcrumbTrail);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbTrail = this.createBreadcrumbs(this.activatedRoute.root);
      this.breadcrumbTrail$.next(this.breadcrumbTrail);
    });
    this.workerService.currentWorker$.subscribe((worker) => {
      this.workerName = worker?.fullName;
      if (worker) {
        this.setBreadcrumbLabel('{workerName}', this.workerName);
      }
    });
  }

  public setBreadcrumbLabel(breadcrumbTokenLabel: string, newLabel: string): void {
    const breadcrumb = find(this.breadcrumbTrail, (b: Breadcrumb) => b.originalLabel === breadcrumbTokenLabel);
    if (breadcrumb) {
      breadcrumb.setDynamicLabel(breadcrumbTokenLabel, newLabel);
    }
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    const child = children[0];
    const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    const breadcrumb = new Breadcrumb(child.snapshot.data['breadcrumb']);
    breadcrumb.url = url;
    if (breadcrumb.label !== null && breadcrumb.label !== undefined) {
      const existingIndex = findIndex(breadcrumbs, (b) => {
        return b.url === url;
      });
      if (existingIndex === -1) {
        if (breadcrumb.originalLabel === BreadcrumbRouteToken.WorkerName && this.workerName) {
          breadcrumb.setDynamicLabel(BreadcrumbRouteToken.WorkerName, this.workerName);
        }
        breadcrumbs.push(breadcrumb);
      }
    }

    return this.createBreadcrumbs(child, url, breadcrumbs);
  }
}
